import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

// import actions
import {
  addMessage,
  getMessages,
  addMessageSuccess,
  getConversationSuccessAction,
  getConversationAction,
} from 'store/actions';

// import images
import {
  initialize as initializeWebSocket,
  close as closeWebSocket,
} from 'store/websocket/websocket_action';
import ConversationUIKit from 'components/Chat/ConversationUIKit';
import { get, post } from 'network/http/api';
import {
  getCookie,
  getQueryFromURL,
  getVisitorUniqueId,
} from 'components/helper';
import AlertInfo from 'components/AlertInfo';
import ChatLoaderWrapper from './ChatLoaderWrapper';
import EmbedRegisterForm from './EmbedRegisterForm';

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const ConversationSessionWrapper = ({
  getMessages,
  addMessage,
  conversationDetail,
  getConversationSuccessAction,
  registerFormStyle,
  addMessageSuccess,
  messages,
  setIsOpenEndChat,
  isOpenEndChat,
}) => {
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [isError, setIsError] = useState(false);
  const [initialWebSocket, setInitialWebSocket] = useState(false);

  useEffect(() => {
    handleOnGetConversationDetail();
  }, []);

  // useEffect(() => {
  //     if (botIsTyping) {
  //         setDisableInput(true)
  //         return
  //     }
  //     setDisableInput(false)
  // }, [botIsTyping])

  const handleOnGetConversationDetail = () => {
    let external_conversation_id = getCookie('external_conversation_id');
    if (!external_conversation_id || external_conversation_id === '') return;

    setIsLoadingPage(true);
    get('/embed/v1/conversation/external/' + external_conversation_id)
      .then(handleConversationResponse)
      .catch((err) => {
        setIsLoadingPage(false);
        setIsError(true);
        setDisableInput(false);
        console.error(err);
      });
  };

  const handleOnCreateConversation = (data) => {
    // check if conversation exists in cookie
    let url = `/embed/v1/conversation`;
    setIsLoadingPage(true);
    setDisableInput(true);
    post(url, data)
      .then(handleConversationResponse)
      .catch((err) => {
        setIsLoadingPage(false);
        setIsError(true);
        setDisableInput(false);
        console.error(err);
      });
  };

  const handleConversationResponse = async (resp) => {
    const { conversation, messages, user } = resp;
    setIsLoadingPage(false);
    setIsError(false);
    setDisableInput(false);
    getConversationSuccessAction(resp);

    // set converstion for 3 days
    let expiredAt = new Date();
    expiredAt.setDate(expiredAt.getDate() + 3);
    document.cookie = `external_conversation_id=${conversation.external_id};expires=${expiredAt};`;
    document.cookie = `risa_visitor_id=${user.external_id};expires=${expiredAt};`;

    // connect to websocket
    if (!initialWebSocket) {
      // wait until layer rendered
      setTimeout(() => {
        // connect to websocket
        initializeWebSocket();
        setInitialWebSocket(true);
      }, 250);
    }

    if (messages && messages.length > 0) {
      // messages.reverse()
      await messages.map(async (message, i) => {
        setTimeout(() => {
          addMessageSuccess(message);
        }, 1000 * i);
      });
    }
  };

  const handleOnAddMessage = (message) => {
    let external_conversation_id = getCookie('external_conversation_id');
    const data = {
      created_at: message.created_at,
      platform_name: 'web',
      type: message.type,
      external_id: message.external_id,
      external_conversation_id: external_conversation_id,
      message: message.message,
      is_question: true,
    };

    addMessage(data);
  };

  const renderConversationBox = () => {
    if (isLoadingPage) {
      return null;
    }

    if (!conversationDetail.id) {
      return (
        <EmbedRegisterForm
          registerFormStyle={registerFormStyle}
          onSubmit={handleOnCreateConversation}
        />
      );
    }

    return (
      <ConversationUIKit
        isQuestion={true}
        disableHeader={true}
        isLoading={isLoadingPage}
        messages={messages}
        disableInput={disableInput}
        onAddMessage={handleOnAddMessage}
        onGetMessages={getMessages}
        conversation={conversationDetail}
        hideInputBox={conversationDetail.status === 'closed'}
        disableSidebar={true}
        isOpenEndChat={isOpenEndChat}
        setIsOpenEndChat={setIsOpenEndChat}
      />
    );
  };

  return (
    <div className="chat-wrapper">
      <ChatLoaderWrapper isLoading={isLoadingPage} />

      {isError && (
        <AlertInfo text={'Something went wrong, please refresh this page'} />
      )}

      {renderConversationBox()}
    </div>
  );
};

const mapStateToProps = ({ ConversationDetail, chat, WebSocket }) => ({
  chats: chat.chats,
  messages: chat.messages,
  conversationDetail: ConversationDetail.conversation,
  webSocketConnected: WebSocket.status === 'connected',
  botIsTyping: chat.botIsTyping,
});

export default connect(mapStateToProps, {
  addMessageSuccess,
  addMessage,
  getMessages,
  getConversationAction,
  getConversationSuccessAction,
})(ConversationSessionWrapper);
