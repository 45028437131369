import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

// import actions
import {
  addMessage,
  getMessages,
  addMessageSuccess,
  getConversationSuccessAction,
  getConversationAction,
} from "store/actions";

// import images
import { initialize as initializeWebSocket, close as closeWebSocket } from 'store/websocket/websocket_action'
import ConversationUIKit from "components/Chat/ConversationUIKit";
import { get } from "network/http/api";
import { getCookie, getQueryFromURL } from "components/helper";
import { JWT_TOKEN_KEY } from "network/http/jwt-token-access";
import AlertInfo from "components/AlertInfo";
import Image from "components/Image";

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const LandingChat = ({
  addMessageSuccess,
  getMessages,
  addMessage,
  conversationDetail,
  getConversationSuccessAction,
  botIsTyping,
  messages }) => {
  const [isLoadingPage, setIsLoadingPage] = useState(false)
  const [disableInput, setDisableInput] = useState(false)
  const [isError, setIsError] = useState(false)
  const [initialWebSocket, setInitialWebSocket] = useState(false)

  const handleSetupAuth = () => {
    let client_id, secret = ''
    if (window.risa_account) {
      client_id = window.risa_account.client_id
      secret = window.risa_account.secret
    } else {
      client_id = getQueryFromURL.client_id
      secret = getQueryFromURL.secret
    }
    let authorization = btoa(`${secret}:${client_id}`)
    document.cookie = `${JWT_TOKEN_KEY}=${authorization}`
  }

  useEffect(() => {
    if (botIsTyping) {
      setDisableInput(true)
      return
    }
    setDisableInput(false)
  }, [botIsTyping])

  const handleOnGetSimulation = useCallback(() => {
    if (isLoadingPage) {
      return
    }
    setIsLoadingPage(true)



    handleSetupAuth()
    setDisableInput(true)
    get('/v1/chat-landing/predefined')
      .then(async (resp) => {
        const { conversation, messages, user } = resp
        setIsLoadingPage(false)
        setIsError(false)
        getConversationSuccessAction(resp)

        let expiredAt = new Date()
        expiredAt.setDate(expiredAt.getDate() + 1)
        document.cookie = `external_conversation_id=${conversation.external_id};expires=${expiredAt};`
        document.cookie = `risa_visitor_id=${user.id};expires=${expiredAt};`


        // connect to websocket
        if (!initialWebSocket) {
          // wait until layer rendered
          setTimeout(() => {
            // connect to websocket
            initializeWebSocket()
            setInitialWebSocket(true)
          }, 250);
        }

        messages.reverse()
        // eslint-disable-next-line array-callback-return
        for (let i = 1; i <= messages.length; i++) {
          await delay(i * 100)
          addMessageSuccess(messages[i - 1])
          if (i === messages.length) {
            setDisableInput(false)
          }
        }

      })
      .catch((err) => {
        setIsLoadingPage(false)
        setIsError(true)
        setDisableInput(false)
        console.error(err)
      })
  }, [])


  useEffect(() => {
    console.log('landing -page')
    if (conversationDetail.id || isLoadingPage) {
      return
    }

    handleOnGetSimulation()
  }, [])

  const handleOnAddMessage = (message) => {
    let external_conversation_id = getCookie('external_conversation_id')
    const data = {
      platform_name: 'web',
      type: message.type,
      external_id: message.external_id,
      external_conversation_id: external_conversation_id,
      message: message.message,
      is_question: message.is_question
    }

    addMessage(data)
  }


  return (
    <React.Fragment>
      <div className="live-chat" style={{
        display: 'block',
        position: "relative",
        height: "600px"
      }}>

        {isError &&
          <AlertInfo text={'Something went wrong, please refresh this page'} />
        }

        <ConversationUIKit
          isQuestion={true}
          disableHeader={true}
          isLoading={isLoadingPage}
          messages={messages}
          disableInput={disableInput}
          onAddMessage={handleOnAddMessage}
          onGetMessages={getMessages}
          conversation={conversationDetail}
          hideInputBox={conversationDetail.status === 'closed'}
          disableSidebar={true} />

        {conversationDetail.status === 'closed' &&
          <div
            onClick={() => window.open("http://member.risa.ai/register", "_blank")}
            className="register-now">
            <div className="text">
              Registrasi Gratis
              <Image className="icon" src="/right-arrow.svg" />
            </div>
          </div>
        }
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = ({ ConversationDetail, chat, WebSocket }) => ({
  chats: chat.chats,
  messages: chat.messages,
  conversationDetail: ConversationDetail.conversation,
  webSocketConnected: WebSocket.status === 'connected',
  botIsTyping: chat.botIsTyping
});

export default connect(mapStateToProps, {
  addMessageSuccess,
  addMessage,
  getMessages,
  getConversationAction,
  getConversationSuccessAction,
})(LandingChat);
