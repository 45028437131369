import React, { useEffect, useCallback, useState, useRef } from 'react';
import {
  Avatar,
  Conversation,
  Sidebar,
} from '@chatscope/chat-ui-kit-react';
import { connect, useDispatch } from 'react-redux';
import { find } from 'lodash';
import DropdownCheckbox from './DropdownCheckbox';
import throttle from 'lodash/throttle';

// action reducer
import {
  getConversationAction,
  fetchConversationAction, 
  setActivePlatforms,
  setPage,
  resetConversationRequestAction,
} from 'store/actions';
import moment from 'moment';
import { get } from 'network/http/api';

let conversationCurrentPage = 1

const ChatLeftSidebarUI = ({
  conversations,
  users,
  getConversationAction,
  conversationDetail,
}) => {
  // set state
  const [totalPage, setTotalPage] = useState(10);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [activeFilter, setActiveFilter] = useState(0);
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState([])
  const [platforms, setPlatforms] = useState([])

  useEffect(()=>{
    get('/platform')
    .then(resp=>{
      setPlatforms(resp)
    })
  }, [])

  const fetchConversationRest = async (
    platform,
    status = 'active,closed',
    page = 1,
    per_page = 15,
  ) => {
    dispatch(fetchConversationAction(platform , status, page, per_page))
  };
  
  const fetchConversation = throttle(fetchConversationRest, 1000)

  const getList = useCallback(async () => {
    await fetchConversation(
        selectedPlatform.join(','),
        selectedStatus.join(','),
        conversationCurrentPage
    );
    
    setIsLoadMore(false);
  }, [
    conversations,
    conversationCurrentPage,
    selectedPlatform,
    selectedStatus,
  ]);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (conversations.length === 0) {
      conversationCurrentPage = 1
      getList();
    }
  }, [conversations.length, getList]);

  useEffect(() => {
    conversationCurrentPage = 1
    getList();
  }, [selectedStatus]);

  const handleOnClick = (conv) => {
    dispatch(getConversationAction(conv.id));
  };

  const handleLoadMore = useCallback(
    async (page, totalPage) => {
      let currPage = page + 1
      if (currPage === totalPage) {
        return;
      }

      setIsLoadMore(true);
      fetchConversation(
          selectedPlatform.join(','),
          selectedStatus.join(','),
          currPage
      )
      setIsLoadMore(false);
    },
    [
      conversations,
      dispatch,
      isLoadMore,
      selectedPlatform,
      selectedStatus,
    ]
  );

  const handleOnSelectFilter = (value) => {
    dispatch(resetConversationRequestAction())
    setActiveFilter(value);
  };

  const handleOnSeletectedPlatforms = (cat) => {
    dispatch(resetConversationRequestAction())
    setSelectedPlatform(cat)
  };

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        console.log('observerTarget', observerTarget, conversationCurrentPage)
        if(conversationCurrentPage < 1) return
        handleLoadMore(conversationCurrentPage, totalPage);
      }
    });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    // return () => {
    //   if (observerTarget.current) {
    //     observer.unobserve(observerTarget.current);
    //   }
    // };
  }, [observerTarget, conversationCurrentPage, totalPage]);

  const renderConversationList = useCallback(() => {
    return (
      <div>
        {conversations?.length === 0 && (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            <p>Belum ada percakapan atau pesan tidak ditemukan.</p>
          </div>
        )}
        {conversations?.length > 0 &&
          conversations?.map((conv) => {
            const user = find(users, { id: conv.user_creator_id });
            const platform = find(platforms, { id: conv.platform_id });
            let flag = null;
            let className = '';
            let fullname = user?.fullname;

            if (conv.status === 'closed') {
              className = 'closed';
              flag = <div className="close-flag">Chat Selesai</div>;
            }

            if (conv.is_human_takeover && conv.status !== 'closed') {
              className += ' is_human_takeover';
              flag = <div className="admin-flag">Diambil Alih Admin</div>;
            }

            if (conv.need_human_takeover && conv.status !== 'closed') {
              className += ' need_human_takeover';
              flag = <div className="admin-flag">Butuh Bantuan Admin</div>;
            }

            if (activeFilter === 1 && !conv.is_human_takeover) {
              return null;
            }

            return (
              <Conversation
                onClick={() => handleOnClick(conv)}
                key={conv.id}
                className={className}
                name={
                  <div
                    style={{
                      display: 'flex',
                      gap: '4px',
                      alignItems: 'center',
                    }}
                  >
                    {fullname} {flag}
                  </div>
                }
                lastActivityTime={moment(conv.last_message_date).fromNow()}
                active={conv.id === conversationDetail.id}
                info={conv.last_message_text ? conv.last_message_text : ''}
              >
                <Avatar
                  src={
                    platform?.platform_name !== 'web'
                      ? `${process.env.PUBLIC_URL}/images/${platform?.platform_name}-logo.svg`
                      : `${process.env.PUBLIC_URL}/images/web-logo.svg`
                  }
                  // size="xs"
                  // size="md"
                />
                <div
                  style={{
                    width: '100px',
                    height: '100px',
                  }}
                >
                  <Avatar
                    src={
                      platform?.platform_name !== 'web'
                        ? `${process.env.PUBLIC_URL}/images/${platform?.platform_name}-logo.svg`
                        : `${process.env.PUBLIC_URL}/images/web-icon.svg`
                    }
                    size="fluid"
                    // size="md"
                  />
                </div>
              </Conversation>
            );
          })}
      </div>
    );
  }, [
    activeFilter,
    conversationDetail.id,
    conversations,
    handleOnClick,
    platforms,
    users,
  ]);

  return (
    <Sidebar position="left">
      <div>
        <div className="p-2 mt-1 mb-1">
          <DropdownCheckbox
            list={platforms}
            selectedPlatforms={selectedPlatform || []}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            setSelectedPlatforms={handleOnSeletectedPlatforms}
          />

          <div className="filter">
            <div
              onClick={() => handleOnSelectFilter(0)}
              className={activeFilter !== 1 ? 'active' : ''}
            >
              Semua Pesan
            </div>
            <div
              onClick={() => handleOnSelectFilter(1)}
              className={activeFilter === 1 ? 'active' : ''}
            >
              Admin
            </div>
          </div>
        </div>
        {conversations?.length === 0 && (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            <p>Belum ada percakapan atau pesan tidak ditemukan.</p>
          </div>
        )}
        <div>{conversations?.length > 0 && renderConversationList()}</div>
        <div ref={observerTarget} id="observer" style={{ height: '5%' }}></div>
      </div>
    </Sidebar>
  );
};

const mapStateToProps = ({ Conversations, ConversationDetail }) => {
  const { conversations, users, total_conversation, platforms } =
    Conversations.data;

  return {
    conversations: conversations,
    users: users,
    totalConversation: total_conversation,
    conversationDetail: ConversationDetail.conversation,
  };
};

export default connect(mapStateToProps, {
  getConversationAction,
})(ChatLeftSidebarUI);
