import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

// import actions
import {
    addMessage,
    getMessages,
    addMessageSuccess,
    getConversationAction,
    getConversationSuccessAction
} from "../store/actions";

// import images
import { initialize as initializeWebSocket, close as closeWebSocket } from 'store/websocket/websocket_action'
import ConversationUIKit from "components/Chat/ConversationUIKit";
import { post } from "network/http/api";
import { getCookie, getQueryFromURL, getUserToken } from "components/helper";
import AlertInfo from "components/AlertInfo";

function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const TestScenario = ({
    addMessageSuccess,
    getConversationSuccess,
    getMessages,
    addMessage,
    conversationDetail,
    getConversationAction,
    botIsTyping,
    messages }) => {
    const [currentUserId, setCurrentUserId] = useState(0)
    const [isLoadingPage, setIsLoadingPage] = useState(false)
    const [disableInput, setDisableInput] = useState(false)
    const [isError, setIsError] = useState(false)
    const [initialWebSocket, setInitialWebSocket] = useState(false)
    // const [scenarioId, setScenarioId] = useState('')
    const [conversationId, setConversationId] = useState('')

    useEffect(() => {
        const { scenarioId } = window.risa_account;
        console.log('scenario id', scenarioId)
        if (conversationId && conversationId != '') {
            createConversation(scenarioId)
        }
    }, [])


    const createConversation = () => {
        if (isLoadingPage) {
            return
        }
        setIsLoadingPage(true)

        const { scenarioId } = window.risa_account;
        setDisableInput(true)
        post(`/scenario/conversation/${scenarioId}/test`, {}, {
            headers: {
                Authorization: getUserToken(),
            },
        })
            .then(async (resp) => {
                const { conversation, messages, user_id } = resp
                setIsLoadingPage(false)
                setIsError(false)
                setDisableInput(false)
                getConversationSuccess(resp)
                setConversationId(conversation.id)


                let expiredAt = new Date()
                expiredAt.setDate(expiredAt.getDate() + 1)
                document.cookie = `external_conversation_id=${conversation.external_id};expires=${expiredAt};`
                setCurrentUserId(user_id)

                let location = new URL(window.location.href);
                location.searchParams.set('conversation_id', conversation.id)
                window.history.replaceState(null, null, location.search);

                // connect to websocket
                if (!initialWebSocket) {
                    // wait until layer rendered
                    setTimeout(() => {
                        // connect to websocket
                        initializeWebSocket(getUserToken())
                        setInitialWebSocket(true)
                    }, 250);
                }

                // messages.reverse()
                messages.map(async (msg) => {
                    addMessageSuccess(msg)
                    await delay(100)
                })

            })
            .catch((err) => {
                setIsLoadingPage(false)
                setIsError(true)
                setDisableInput(false)
                console.error(err)
            })
    }

    useEffect(() => {
        if (conversationDetail.id || isLoadingPage) {
            return
        }

        createConversation()
    }, [])

    const handleOnAddMessage = (message) => {
        let external_conversation_id = getCookie('external_conversation_id')
        const data = {
            sender_id: currentUserId,
            created_at: message.created_at,
            // platform_name: 'web',
            type: message.type,
            external_id: message.external_id,
            external_conversation_id: external_conversation_id,
            message: message.message,
            is_question: message.is_question
        }

        addMessage(data)
    }

    return (
        <React.Fragment>
            <div className="live-chat">

                {isError &&
                    <AlertInfo text={'Something went wrong, please refresh this page'} />
                }

                <ConversationUIKit
                    isQuestion={true}
                    disableHeader={true}
                    isLoading={isLoadingPage}
                    messages={messages}
                    disableInput={disableInput}
                    onAddMessage={handleOnAddMessage}
                    onGetMessages={getMessages}
                    conversation={conversationDetail}
                    hideInputBox={conversationDetail.status === 'closed'}
                    disableSidebar={true} />
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = ({ chat, ConversationDetail, WebSocket }) => {
    return {
        chats: chat.chats,
        messages: chat.messages,
        conversationDetail: ConversationDetail.conversation,
        webSocketConnected: WebSocket.status === 'connected',
        botIsTyping: chat.botIsTyping
    }
};

export default connect(mapStateToProps, {
    addMessageSuccess,
    addMessage,
    getMessages,
    getConversationAction,
    getConversationSuccess: getConversationSuccessAction
})(TestScenario);
